import React, { useRef } from "react";
import Reveal from "react-reveal/Reveal";

const SliderPrevArrow = ({
  className,
  style,
  onClick,
  short = false,
  title = "",
  uuid
}) => {
  const ref = useRef();

 const handleAnimation = () => {
   // document.querySelector('#lfirstanim').beginElement();
   ref.current.beginElement();
 };  
  const dur1 = "0.07s";
  const dur2 = "0.15s";
  return (
    <Reveal onReveal={handleAnimation}>
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        role="button"
        tabIndex="0"
      >
        <svg
          width={short ? "290px" : "313px"}
          height="227px"
          viewBox="0 0 313 227"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          id={`${uuid}lfirst`}
        >
          <animate
            xlinkHref={`#${uuid}lfirst`}
            begin="lndefinite"
            fill="freeze"
            id={`${uuid}lfirstanim`}
            ref={ref}
          />
          <title>{title}</title>
          <g
            id={`${uuid}lHelmut4`}
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id={`${uuid}l00HelmutTools_HomeSTART`}
              transform="translate(1.000000, -3092.000000) "
            >
              <g
                id={`${uuid}lArrowLeft`}
                transform="translate(155.000000, 3205.500000) scale(-1, 1) rotate(180.000000) translate(-155.000000, -3205.500000) translate(-1.000000, 3093.000000)"
              >
                <g id={`${uuid}lGroup4`} transform="translate(173.500000, 0.000000)">
                  {/* ------------rrethi i bardh--------------- */}
                  <ellipse
                    id={`${uuid}lOvalwhitecircle`}
                    fill="#FFFFFF"
                    transform="translate(63.171115, 111.145945) scale(-1, 1) rotate(-60.000000) translate(-63.171115, -111.145945) "
                    cx="63.1711149"
                    cy="111.145945"
                    rx="0"
                    ry="0"
                  >
                    <animate
                      xlinkHref={`#${uuid}lOvalwhitecircle`}
                      attributeName="rx"
                      dur={dur1}
                      begin={`${uuid}lgotonextwhitecircle.begin + 0.07s`}
                      values="0;9.1711149"
                      fill="freeze"
                      id={`${uuid}lgotolines`}
                    />
                    <animate
                      xlinkHref={`#${uuid}lOvalwhitecircle`}
                      attributeName="ry"
                      dur={dur1}
                      begin={`${uuid}lgotonextwhitecircle.begin + 0.07s`}
                      values="0;9.14594491"
                      fill="freeze"
                      id={`${uuid}lgotolines`}
                    />
                  </ellipse>

                  {/* ------------rrethi posht--------------- */}
                  <ellipse
                    id={`${uuid}lOvalbottomcircle`}
                    stroke="#FFFFFF"
                    transform="translate(117.561224, 20.683300) scale(-1, 1) rotate(-60.000000) translate(-117.561224, -20.683300) "
                    cx="117.561224"
                    cy="20.6832997"
                    rx="0"
                    ry="0"
                  >
                    <animate
                      xlinkHref={`#${uuid}lOvalbottomcircle`}
                      attributeName="rx"
                      dur={dur2}
                      begin={`${uuid}lgototwocircles.begin + 0.15s`}
                      values="0;14.6737838"
                      fill="freeze"
                    />
                    <animate
                      xlinkHref={`#${uuid}lOvalbottomcircle`}
                      attributeName="ry"
                      dur={dur2}
                      begin={`${uuid}lgototwocircles.begin + 0.15s`}
                      values="0;14.6335119"
                      fill="freeze"
                    />
                  </ellipse>

                  {/* ------------rrethi i lart--------------- */}
                  <ellipse
                    id={`${uuid}lOvaltopcircle`}
                    stroke="#FFFFFF"
                    transform="translate(115.428964, 204.316700) scale(-1, 1) rotate(-60.000000) translate(-115.428964, -204.316700) "
                    cx="115.428964"
                    cy="204.3167"
                    rx="0"
                    ry="0"
                  >
                    <animate
                      xlinkHref={`#${uuid}lOvaltopcircle`}
                      attributeName="rx"
                      dur={dur2}
                      begin={`${uuid}lgototwocircles.begin + 0.15s`}
                      values="0;14.6737838"
                      fill="freeze"
                    />
                    <animate
                      xlinkHref={`#${uuid}lOvaltopcircle`}
                      attributeName="ry"
                      dur={dur2}
                      begin={`${uuid}lgototwocircles.begin + 0.15s`}
                      values="0;14.6335119"
                      fill="freeze"
                    />
                  </ellipse>

                  <g id={`${uuid}lGroup40`} transform="translate(0.000000, 86.000000)">
                    {/* <g id="l-Oval-2" > */}
                    {/* ------------rrethi i zi i madh--------------- */}
                    <ellipse
                      id={`${uuid}lOvalblackcircle`}
                      transform="translate(24.353333, 24.448980) scale(-1, 1) rotate(-60.000000) translate(-24.353333, -24.448980) translate(7.000000, 7.000000)"
                      fill="#000000"
                      stroke="#FFFFFF"
                      cx="17.3533333"
                      cy="17.4489796"
                      rx="0"
                      ry="0"
                    >
                      <animate
                        xlinkHref={`#${uuid}lOvalblackcircle`}
                        attributeName="rx"
                        dur={dur2}
                        begin={`${uuid}lgotoblackcircle.begin + 0.15s`}
                        values="0;17.3533333"
                        fill="freeze"
                        id="lgotowhitecircle"
                      />
                      <animate
                        xlinkHref={`#${uuid}lOvalblackcircle`}
                        attributeName="ry"
                        dur={dur2}
                        begin={`${uuid}lgotoblackcircle.begin + 0.15s`}
                        values="0;17.4489796"
                        fill="freeze"
                        id={`${uuid}lgotowhitecircle`}
                      />
                    </ellipse>
                    {/* </g> */}
                    {/* ------------rrethi i bardh brenda te ziut--------------- */}
                    <ellipse
                      id={`${uuid}lwhiteinsideblack`}
                      fill="#FFFFFF"
                      transform="translate(24.353333, 24.448980) scale(-1, 1) rotate(-60.000000) translate(-24.353333, -24.448980) "
                      cx="24.3533333"
                      cy="24.4489796"
                      rx="0"
                      ry="0"
                    >
                      <animate
                        xlinkHref={`#${uuid}lwhiteinsideblack`}
                        attributeName="rx"
                        dur={dur2}
                        begin={`${uuid}lgotowhitecircle.begin + 0.15s`}
                        values="0;9.1711149"
                        fill="freeze"
                        id={`${uuid}lgotosmallline`}
                      />
                      <animate
                        xlinkHref={`#${uuid}lwhiteinsideblack`}
                        attributeName="ry"
                        dur={dur2}
                        begin={`${uuid}lgotowhitecircle.begin + 0.15s`}
                        values="0;9.14594491"
                        fill="freeze"
                        id={`${uuid}lgotosmallline`}
                      />
                    </ellipse>
                  </g>

                  {/* ------------vija posht-------------- */}
                  <line
                    id={`${uuid}llinebottom`}
                    x1="109.467897"
                    y1="32.9384074"
                    x2="109.467897"
                    y2="32.9384074"
                    stroke="#FFFFFF"
                    transform="translate(88.289927, 68.428976) scale(-1, 1) rotate(-240.000000) translate(-88.289927, -68.428976) "
                  >
                    <animate
                      xlinkHref={`#${uuid}llinebottom`}
                      attributeName="x1"
                      dur={dur2}
                      begin={`${uuid}lgotolines.begin + 0.07s`}
                      from="109.46789"
                      to="67.1119567"
                      fill="freeze"
                      id={`${uuid}lgototwocircles`}
                    />
                    <animate
                      xlinkHref={`#${uuid}llinebottom`}
                      attributeName="y1"
                      dur={dur2}
                      begin={`${uuid}lgotolines.begin + 0.07s`}
                      from="32.9384074"
                      to="103.919545"
                      fill="freeze"
                      id={`${uuid}lgototwocircles`}
                    />
                  </line>

                  {/* ------------vija lart--------------- */}
                  <line
                    id={`${uuid}llinetop`}
                    x1="129.813233"
                    y1="154.477035"
                    x2="129.813233"
                    y2="154.477035"
                    stroke="#FFFFFF"
                    transform="translate(87.396949, 154.577850) scale(-1, 1) rotate(-60.000000) translate(-87.396949, -154.577850) "
                  >
                    <animate
                      xlinkHref={`#${uuid}llinetop`}
                      attributeName="x1"
                      dur={dur2}
                      begin={`${uuid}lgotolines.begin + 0.07s`}
                      from="129.813233"
                      to="44.9806651"
                      fill="freeze"
                      id={`${uuid}lgototwocircles`}
                    />
                    <animate
                      xlinkHref={`#${uuid}llinetop`}
                      attributeName="y1"
                      dur={dur2}
                      begin={`${uuid}lgotolines.begin + 0.07s`}
                      from="154.477035"
                      to="154.678665"
                      fill="freeze"
                      id={`${uuid}lgototwocircles`}
                    />
                  </line>

                  {/* ------------vija e shkurter--------------- */}
                  <line
                    id={`${uuid}lsmallline`}
                    x1="41.2066666"
                    y1="110.5"
                    x2="41.2066666"
                    y2="110.5"
                    stroke="#FFFFFF"
                  >
                    <animate
                      xlinkHref={`#${uuid}lsmallline`}
                      attributeName="x1"
                      dur={dur1}
                      begin={`${uuid}lgotosmallline.begin + 0.15s`}
                      from="41.2066666"
                      to="55.5"
                      fill="freeze"
                      id={`${uuid}lgotonextwhitecircle`}
                    />
                  </line>

                  {/* <path d="M44.8441531,106.332572 C44.8441531,106.332572 47.5845108,109.833414 53.065226,116.835097 C47.5845108,109.833414 44.8441531,106.332572 44.8441531,106.332572 Z" stroke="#FFFFFF" transform="translate(48.954690, 111.583835) scale(-1, 1) rotate(-53.000000) translate(-48.954690, -111.583835) " /> */}
                </g>

                {/* ------------line 1--------------- */}
                <line
                  id={`${uuid}lfirstline`}
                  x1="0.5"
                  y1="110.5"
                  x2={short ? "60" : 0.5}
                  y2="110.5"
                  stroke="#FFFFFF"
                >
                  <animate
                    xlinkHref={`#${uuid}lfirstline`}
                    attributeName="x1"
                    dur={dur2}
                    begin={`${uuid}lfirstanim.begin + 0.07s`}
                    from="0"
                    to="180.5"
                    id={`${uuid}lgotoblackcircle`}
                    fill="freeze"
                  />
                </line>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </Reveal>
  );
};
export default SliderPrevArrow;
